import React, { useState, useEffect } from 'react';
import { db } from './firebase.js';
import { collection, getDocs } from 'firebase/firestore';

const BarChart = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const fetchTotalUsers = async () => {
      const usersRef = collection(db, 'Users');
      const querySnapshot = await getDocs(usersRef);
      setTotalUsers(querySnapshot.size);
    };

    fetchTotalUsers();
  }, []);

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    if (password === 'miley123') {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
      setPassword('');
    }
  };

  if (!isAuthenticated) {
    return (
      <div style={styles.loginContainer}>
        <input
          type="password"
          value={password}
          onChange={handlePasswordChange}
          placeholder="Enter Password"
          style={styles.input}
        />
        <button onClick={handleLogin} style={styles.button}>Log In</button>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <h3>Total Users</h3>
        <p>{totalUsers}</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    height: '100vh',
    backgroundColor: '#FAF7F1',
    padding: '10px 0',
  },
  card: {
    background: '#fff',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    padding: '20px',
    textAlign: 'center',
    width: '300px',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#FAF7F1',
  },
  input: {
    margin: '10px',
    padding: '8px',
    width: '200px',
  },
  button: {
    padding: '10px 20px',
    cursor: 'pointer',
  }
};

export default BarChart;
