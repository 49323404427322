import React, { createContext, useContext, useState, useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase.js';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setUser(user);
        // Retrieve access token from local storage if available
        const storedToken = localStorage.getItem('accessToken');
        if (storedToken) {
          setAccessToken(storedToken);
        }
      } else {
        setUser(null);
        setAccessToken(null);
        // Clear access token from local storage if user signs out
        localStorage.removeItem('accessToken');
      }
    });

    return () => unsubscribe();
  }, []);

  const signOut = () => {
    auth.signOut().then(() => {
      setUser(null);
      setAccessToken(null);
      localStorage.removeItem('accessToken');
    }).catch((error) => {
      console.error("Sign out error:", error);
    });
  };

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/calendar');
    provider.addScope('https://www.googleapis.com/auth/calendar.events');

    try {
      const result = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      setAccessToken(token);
      // Save access token to local storage
      localStorage.setItem('accessToken', token);
      console.log("Google sign in successful, token:", token);
    } catch (error) {
      console.error("Google sign in error", error);
    }
  };

  const value = {
    user,
    accessToken,
    signInWithGoogle,
    signOut,
    setAccessToken,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
