// src/hooks/useIntercom.js

import { useEffect } from 'react';

const useIntercom = (user) => {
  useEffect(() => {
    const intercomAppId = 'litk1zuh'; // Your Intercom App ID

    const loadIntercom = () => {
      const script = document.createElement('script');
      script.src = `https://widget.intercom.io/widget/${intercomAppId}`;
      script.async = true;
      document.body.appendChild(script);
      script.onload = () => initializeIntercom();
    };

    const initializeIntercom = () => {
      window.Intercom('boot', {
        app_id: intercomAppId,
        name: user.name,
        email: user.email,
        user_id: user.id,
        created_at: user.created_at,
      });
    };

    if (user) {
      if (!window.Intercom) {
        loadIntercom();
      } else {
        initializeIntercom();
      }
    } else if (window.Intercom) {
      window.Intercom('shutdown');
    }

    return () => {
      // Cleanup Intercom when the component unmounts or user logs out
      window.Intercom && window.Intercom('shutdown');
      // Remove the Intercom script from the document
      const intercomScript = document.querySelector(`script[src="https://widget.intercom.io/widget/${intercomAppId}"]`);
      if (intercomScript) intercomScript.remove();
    };
  }, [user]);
};

export default useIntercom;
