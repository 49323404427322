import React, { useEffect } from 'react';
import { signInWithCustomToken } from 'firebase/auth';
import { auth } from './firebase.js';

const GoogleCallback = ({ onClose }) => {
  const params = new URLSearchParams(window.location.search);
  const firebaseToken = params.get('token');
  
  useEffect(async () => {
    const result = await signInWithCustomToken(auth, firebaseToken);
    window.close();
  });

  return (
    <div>Google Callback</div>
  );
};

export default GoogleCallback;
