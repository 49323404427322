import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from './firebase.js';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import Chart from 'chart.js/auto';

const CalendarInsights = () => {
  const [userName, setUserName] = useState('');
  const [doubleBookings, setDoubleBookings] = useState(0);
  const [highMeetingDensity, setHighMeetingDensity] = useState(0);
  const [unusedTimeSlots, setUnusedTimeSlots] = useState(0);
  const [averageMeetingDuration, setAverageMeetingDuration] = useState(0);
  const [weeklyMeetingTrends, setWeeklyMeetingTrends] = useState(Array(7).fill(0));
  const [meetingTimeDistribution, setMeetingTimeDistribution] = useState({ morning: 0, afternoon: 0, evening: 0 });

  const doubleBookingsChartRef = useRef(null);
  const highDensityChartRef = useRef(null);
  const unusedSlotsChartRef = useRef(null);
  const durationInefficiencyChartRef = useRef(null);
  const weeklyMeetingTrendsChartRef = useRef(null);
  const meetingTimeDistributionChartRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userDocRef = doc(db, 'Users', firebaseUser.uid);
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          setUserName(userDoc.data().name);

          // Fetch Google Calendar data
          const accessToken = userDoc.data().googleAccessToken;
          fetchGoogleCalendarData(accessToken);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchGoogleCalendarData = async (accessToken) => {
    try {
      const now = new Date();
      const lastMonth = new Date();
      lastMonth.setMonth(now.getMonth() - 1);

      const response = await axios.get('https://www.googleapis.com/calendar/v3/calendars/primary/events', {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        params: {
          timeMin: lastMonth.toISOString(),
          timeMax: now.toISOString(),
          singleEvents: true,
          orderBy: 'startTime'
        }
      });

      calculateInsights(response.data.items);
      createCharts(response.data.items);
    } catch (error) {
      console.error('Error fetching Google Calendar data:', error);
    }
  };

  const calculateInsights = (events) => {
    let doubleBookingCount = 0;
    let highDensityCount = 0;
    let unusedTimeSlotsCount = 0;
    let totalMeetingDuration = 0;
    let totalMeetings = events.length;
    const daysOfWeek = Array(7).fill(0);
    const timeOfDay = { morning: 0, afternoon: 0, evening: 0 };

    // Assuming events are ordered by start time
    for (let i = 0; i < events.length - 1; i++) {
      const currentEvent = new Date(events[i].start.dateTime || events[i].start.date);
      const nextEvent = new Date(events[i + 1].start.dateTime || events[i + 1].start.date);

      if (currentEvent <= nextEvent && new Date(events[i].end.dateTime || events[i].end.date) > nextEvent) {
        doubleBookingCount++;
      }

      // Check for back-to-back meetings
      const endCurrentEvent = new Date(events[i].end.dateTime || events[i].end.date);
      if (endCurrentEvent.getTime() === nextEvent.getTime()) {
        highDensityCount++;
      }

      // Check for unused time slots
      const diff = (nextEvent - endCurrentEvent) / (1000 * 60); // difference in minutes
      if (diff > 30) { // consider slots greater than 30 minutes as unused
        unusedTimeSlotsCount += diff / 60; // convert to hours
      }

      // Calculate meeting duration
      const duration = (new Date(events[i].end.dateTime || events[i].end.date) - new Date(events[i].start.dateTime || events[i].start.date)) / (1000 * 60); // duration in minutes
      totalMeetingDuration += duration;

      // Count meetings per day of the week
      const day = currentEvent.getDay();
      daysOfWeek[day]++;

      // Count meetings per time of day
      const hour = currentEvent.getHours();
      if (hour < 12) {
        timeOfDay.morning++;
      } else if (hour < 18) {
        timeOfDay.afternoon++;
      } else {
        timeOfDay.evening++;
      }
    }

    setDoubleBookings(doubleBookingCount);
    setHighMeetingDensity(highDensityCount);
    setUnusedTimeSlots(unusedTimeSlotsCount.toFixed(1)); // limit to 1 decimal place
    setAverageMeetingDuration((totalMeetingDuration / totalMeetings).toFixed(1)); // average meeting duration
    setWeeklyMeetingTrends(daysOfWeek);
    setMeetingTimeDistribution(timeOfDay);
  };

  const createCharts = () => {
    // Double Bookings Chart
    const ctx1 = doubleBookingsChartRef.current.getContext('2d');
    new Chart(ctx1, {
      type: 'line',
      data: {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        datasets: [{
          label: 'Double Bookings',
          data: [doubleBookings, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 165, 0, 0.2)',
          borderColor: 'rgba(255, 165, 0, 1)',
          borderWidth: 2,
          tension: 0.4,
          fill: true,
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#e0e0e0'
            }
          },
          x: {
            ticks: {
              color: '#e0e0e0'
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: '#e0e0e0'
            }
          }
        }
      }
    });

    // High Meeting Density Chart
    const ctx2 = highDensityChartRef.current.getContext('2d');
    new Chart(ctx2, {
      type: 'line',
      data: {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        datasets: [{
          label: 'High Meeting Density',
          data: [highMeetingDensity, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 165, 0, 0.2)',
          borderColor: 'rgba(255, 165, 0, 1)',
          borderWidth: 2,
          tension: 0.4,
          fill: true,
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#e0e0e0'
            }
          },
          x: {
            ticks: {
              color: '#e0e0e0'
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: '#e0e0e0'
            }
          }
        }
      }
    });

    // Unused Time Slots Chart
    const ctx3 = unusedSlotsChartRef.current.getContext('2d');
    new Chart(ctx3, {
      type: 'line',
      data: {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        datasets: [{
          label: 'Unused Time Slots (hours)',
          data: [unusedTimeSlots, 0, 0, 0, 0],
          backgroundColor: 'rgba(255, 165, 0, 0.2)',
          borderColor: 'rgba(255, 165, 0, 1)',
          borderWidth: 2,
          tension: 0.4,
          fill: true,
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#e0e0e0'
            }
          },
          x: {
            ticks: {
              color: '#e0e0e0'
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: '#e0e0e0'
            }
          }
        }
      }
    });

    // Meeting Duration Inefficiency Chart
    const ctx4 = durationInefficiencyChartRef.current.getContext('2d');
    new Chart(ctx4, {
      type: 'line',
      data: {
        labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4', 'Week 5'],
        datasets: [{
          label: 'Meeting Duration (minutes)',
          data: [averageMeetingDuration, 0, 0, 0, 0],
          backgroundColor: 'rgba(0, 255, 0, 0.2)',
          borderColor: 'rgba(0, 255, 0, 1)',
          borderWidth: 2,
          tension: 0.4,
          fill: true,
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#e0e0e0'
            }
          },
          x: {
            ticks: {
              color: '#e0e0e0'
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: '#e0e0e0'
            }
          }
        }
      }
    });

    // Weekly Meeting Trends Chart
    const ctx5 = weeklyMeetingTrendsChartRef.current.getContext('2d');
    new Chart(ctx5, {
      type: 'bar',
      data: {
        labels: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        datasets: [{
          label: 'Number of Meetings',
          data: weeklyMeetingTrends,
          backgroundColor: 'rgba(0, 255, 0, 0.2)',
          borderColor: 'rgba(0, 255, 0, 1)',
          borderWidth: 2,
        }]
      },
      options: {
        scales: {
          y: {
            beginAtZero: true,
            ticks: {
              color: '#e0e0e0'
            }
          },
          x: {
            ticks: {
              color: '#e0e0e0'
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: '#e0e0e0'
            }
          }
        }
      }
    });

    // Meeting Time Distribution Chart
    const ctx6 = meetingTimeDistributionChartRef.current.getContext('2d');
    new Chart(ctx6, {
      type: 'pie',
      data: {
        labels: ['Morning', 'Afternoon', 'Evening'],
        datasets: [{
          label: 'Meeting Time Distribution',
          data: [meetingTimeDistribution.morning, meetingTimeDistribution.afternoon, meetingTimeDistribution.evening],
          backgroundColor: ['rgba(0, 255, 0, 0.2)', 'rgba(0, 255, 0, 0.4)', 'rgba(0, 255, 0, 0.6)'],
          borderColor: 'rgba(0, 255, 0, 1)',
          borderWidth: 2,
        }]
      },
      options: {
        plugins: {
          legend: {
            position: 'bottom',
            labels: {
              color: '#e0e0e0'
            }
          }
        }
      }
    });
  };

  return (
    <div className="calendar-insights-container">
      <p className="page-title">
        I've analyzed your calendar data for the last month and discovered some key insights. Here are the findings and suggestions for improvement:
      </p>
      <div className="cards-container">
        <div className="card">
          <h2 className="card-title">Double Bookings</h2>
          <p>You had <strong>{doubleBookings}</strong> double bookings last month.</p>
          <p><em>Avoid overlapping meetings to stay on top of your schedule.</em></p>
          <div className="chart-container">
            <canvas id="doubleBookingsChart" ref={doubleBookingsChartRef}></canvas>
          </div>
        </div>
        <div className="card">
          <h2 className="card-title">High Meeting Density</h2>
          <p>Your schedule had <strong>{highMeetingDensity}</strong> instances of back-to-back meetings.</p>
          <p><em>Consider adding breaks between meetings to avoid burnout.</em></p>
          <div className="chart-container">
            <canvas id="highDensityChart" ref={highDensityChartRef}></canvas>
          </div>
        </div>
        <div className="card">
          <h2 className="card-title">Unused Time Slots</h2>
          <p>You had <strong>{unusedTimeSlots}</strong> hours of unused time slots last month.</p>
          <p><em>Utilize these gaps with focused work sessions or short meetings.</em></p>
          <div className="chart-container">
            <canvas id="unusedSlotsChart" ref={unusedSlotsChartRef}></canvas>
          </div>
        </div>
      </div>
      <div className="cards-container">
        <div className="card">
          <h2 className="card-title">Meeting Duration Inefficiency</h2>
          <p>Average meeting duration: <strong>{averageMeetingDuration}</strong> minutes.</p>
          <p><em>Many meetings could be shortened to 45 minutes to save time.</em></p>
          <div className="chart-container">
            <canvas id="durationInefficiencyChart" ref={durationInefficiencyChartRef}></canvas>
          </div>
        </div>
        <div className="card">
          <h2 className="card-title">Weekly Meeting Trends</h2>
          <p>Number of meetings per day.</p>
          <p><em>Observe your busiest days and plan accordingly.</em></p>
          <div className="chart-container">
            <canvas id="weeklyMeetingTrendsChart" ref={weeklyMeetingTrendsChartRef}></canvas>
          </div>
        </div>
        <div className="card">
          <h2 className="card-title">Meeting Time Distribution</h2>
          <p>Distribution of meetings throughout the day.</p>
          <p><em>Ensure a balanced distribution of meetings to avoid fatigue.</em></p>
          <div className="chart-container">
            <canvas id="meetingTimeDistributionChart" ref={meetingTimeDistributionChartRef}></canvas>
          </div>
        </div>
      </div>
      <style>
        {`
          .calendar-insights-container {
            background-color: #121212 !important;
            color: #ffffff !important;
            min-height: 100vh !important;
            display: flex !important;
            flex-direction: column !important;
            justify-content: flex-start !important;
            align-items: center !important;
            padding: 20px !important;
            box-sizing: border-box !important;
            width: 100% !important;
          }

          .page-title {
            color: #ffffff !important;
            font-size: 18px !important;
            margin-bottom: 40px !important;
            max-width: 800px !important;
            text-align: center !important;
          }

          .cards-container {
            display: flex !important;
            gap: 20px !important;
            justify-content: center !important;
            align-items: flex-start !important;
            width: 100% !important;
            margin-bottom: 20px !important;
          }

          .card {
            background: #1e1e1e !important;
            border-radius: 8px !important;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3) !important;
            padding: 20px !important;
            text-align: center !important;
            width: 300px !important;
          }

          .card-title {
            color: #FE7F5E !important;
            text-align: center !important;
            width: 100% !important;
            left: 0 !important;
            right: 0 !important;
            margin-left: auto !important;
            margin-right: auto !important;
          }

          p {
            color: #e0e0e0 !important;
            margin: 10px 0 !important;
          }

          em {
            color: #999 !important;
            margin: 10px 0 !important;
          }

          .chart-container {
            width: 100% !important;
            height: 150px !important;
            margin-top: 20px !important;
          }

          canvas {
            background-color: #1e1e1e !important;
          }
        `}
      </style>
    </div>
  );
};

export default CalendarInsights;
