import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useAuth } from './AuthContext.js';  // Ensure you import useAuth hook properly
import { loadStripe } from '@stripe/stripe-js';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
// test
const endpointSecret = process.env.STRIPE_ENDPOINT_SECRET; // Ensure this is securely set in your environment variables


const Billing = () => {
  const { user } = useAuth();  // Use useAuth to get the current user and other auth-related data
  const stripe = useStripe();
  const elements = useElements();
  const [subscriptionStatus, setSubscriptionStatus] = useState('Loading...');

  useEffect(() => {
    if (user) {
      fetchSubscriptionStatus(user.uid); // Pass user.uid as the userId parameter
    }
  }, [user]);

  const fetchSubscriptionStatus = async (userId) => {
    try {
      const response = await axios.get(`https://backend.heyava.ai:3001/get-subscription-status?userId=${userId}`);
      setSubscriptionStatus(response.data.status);  // Update state with the status from the server
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      if (error.response && error.response.data && error.response.data.subscribed === false) {
        setSubscriptionStatus('No Subscription');  // Handle "No such customer" case as no subscription
      } else {
        setSubscriptionStatus('Failed to load subscription status');  // Handle other errors
      }
    }
  };
  
  

  const handleSubscribe = async () => {
    try {
      const userId = user.uid;
      console.log('Creating checkout session for userId:', userId);

      const response = await axios.post('https://backend.heyava.ai:3001/create-checkout-session', { priceId: 'price_1PCN2dEP9rQUTp1GGuBJUqXT', userId });
      console.log('Backend response:', response);

      const { url } = response.data;

      console.log('Redirecting to Stripe URL:', url);

      // Redirecting to Stripe checkout page directly using the URL from backend response
      window.location.href = url;
    } catch (error) {
      console.error('Error creating checkout session:', error);
    }
  };
  
  

  const SubscribeButton = () => {
    return <button onClick={handleSubscribe}>Subscribe</button>;
  };

  const handleCancelSubscription = async () => {
    axios.post('https://backend.heyava.ai:3001/cancel-subscription', { 
      userId: user.uid, 
    })      .then(() => {
        setSubscriptionStatus('No Subscription');
      }).catch(error => {
        console.error('Error cancelling subscription:', error);
        alert('Failed to cancel subscription');
      });
  };

  return (
    <>
      <div className="container">
        <div className="welcome-message">Billing Information</div>
        <div className="card-container">
          <div className="card">
            {subscriptionStatus === 'No Subscription' ? (
 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>              <h3>Free Tier</h3>
              <p>Subscribe now to gain full access.</p>
              <button className="sign-out" onClick={handleSubscribe}>Subscribe</button>
            </div>
            ) : (
              <>
                <h3>Manage Your Subscription</h3>
                <p>You are currently subscribed to the Basic Plan at $19.99/month.</p>
                <button className="sign-out" onClick={handleCancelSubscription}>Cancel Subscription</button>
              </>
            )}
          </div>
        </div>
      </div>
      <style>{`
  .container {
    max-width: 1200px;
    margin: 50px auto 0; /* Adjust top margin */
    padding: 20px;
  }

  .welcome-message {
    color: #5c6bc0;
    font-size: 2em;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .guide-link {
    display: block;
    color: #ef5350;
    font-weight: bold;
    text-decoration: none;
    background-color: transparent;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s;
    padding: 10px 20px;
    text-align: center;
    margin: 10px 0;
    cursor: pointer;
  }

  .guide-link:hover {
    border-bottom: 2px solid #ef5350;
  }

  .sign-out {
    background-color: #ef5350;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    display: block; /* Make sure button takes full width */
    text-align: center; /* Center text within the button */
    margin-top: 20px; /* Space above the button */
    align: center;
    width: auto; /* Adjust the width as necessary */

  }

  .sign-out:hover {
    background-color: #e53935;
  }

  .card-container {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }

  .card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    flex: 1;
    padding: 20px;
    text-align: center;
    margin-top: 25px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: center; /* Ensure all contents inside the card are centered horizontally */
  }

  .card h3 {
    font-size: 1.25em;
    margin-bottom: 15px;
  }

  .card p {
    font-size: 1em;
    margin-bottom: 15px;
  }
`}</style>

    </>
  );
};

export default Billing;