import React, { useEffect } from 'react';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth, db } from './firebase.js'; // Ensure you import the Firestore instance
import { doc, setDoc, getDoc } from 'firebase/firestore';
import './SignIn.css'; // Assuming you have a separate CSS file for SignIn component
import logo from './logo.png'; // Adjust the path to your logo.png
import googleLogo from './googlelogo.png'; // Update the path if necessary

const SignIn = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('access_token');
    const refreshToken = urlParams.get('refresh_token');
    const expiresIn = urlParams.get('expires_in');

    if (accessToken && refreshToken && expiresIn) {
      const saveTokens = async () => {
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, 'Users', user.uid);
          await setDoc(userRef, {
            accessToken: accessToken,
            refreshToken: refreshToken,
            tokenExpiryDate: parseInt(expiresIn, 10),
          }, { merge: true });
          console.log("Tokens saved successfully");
        }
      };
      saveTokens().catch(console.error);
    }
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/calendar');
    provider.addScope('https://www.googleapis.com/auth/calendar.events');
    provider.setCustomParameters({
      access_type: 'offline',
      prompt: 'consent',
    });

    

    
    try {
      const result = await signInWithPopup(auth, provider)
      const credential = GoogleAuthProvider.credentialFromResult(result);
      console.log(credential);
      console.log(result);
      console.log("Google sign in initiated, check redirected URL for tokens.");
    } catch (error) {
      console.error("Google sign in error", error);
    }
  };

  const signInWithGoogle2 = async () => {
    const baseUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = new URLSearchParams({ 
      client_id: '1089731370690-c2l2gp71jgoefcduj7s07unknnqi03mj.apps.googleusercontent.com',
      redirect_uri: 'https://backend.heyava.ai:3001/google/callback', 
      response_type: 'code', 
      scope: ['email', 'openid', 'profile', 'https://www.googleapis.com/auth/calendar', 'https://www.googleapis.com/auth/calendar.events'].join(' '), 
      access_type: 'offline', 
      prompt: 'consent' 
    });
    const googleOauthUrl = `${baseUrl}?${params.toString()}`;
    window.open(googleOauthUrl, '_blank');
  }

  const fetchUserTimezone = async (accessToken, uid) => {
    try {
      const response = await fetch('https://www.googleapis.com/calendar/v3/users/me/settings/timezone', {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Accept': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch timezone');
      }

      const data = await response.json();
      const timezone = data.value;

      await setDoc(doc(db, 'Users', uid), { timezone }, { merge: true });
      console.log("Timezone updated successfully:", timezone);
    } catch (error) {
      console.error("Error fetching or updating timezone:", error);
    }
  };

  return (
    <div className="sign-in">
      <div className="logo-container">
        <img src={logo} alt="Ava Logo" className="logo" />
        <span className="logo-text">Sign into Ava</span>
      </div>
      <p className="welcome-text">Turn emails into scheduled meetings with zero hassle.</p>
      <button className="gsi-material-button" onClick={signInWithGoogle2}>
        <img src={googleLogo} alt="Google logo" className="gsi-material-button-icon" />
        <span>Sign in with Google</span>
      </button>
      <div className="footer">
        © 2024 Ava AI, Inc.
      </div>
    </div>
  );
};

export default SignIn;
