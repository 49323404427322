import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext.js';
import { db } from './firebase.js'; // Import the Firestore instance from firebase.js
import { doc, getDoc, updateDoc } from 'firebase/firestore'; // Import Firestore functions

const Welcome = () => {
  const { user } = useAuth(); // Get the current user
  const [currentStep, setCurrentStep] = useState(0);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const userDocRef = doc(db, 'Users', user.uid); // Correctly reference the Firestore document
          const userDoc = await getDoc(userDocRef); // Fetch the document
          if (userDoc.exists()) {
            const data = userDoc.data();
            console.log('User data:', data); // Logging user data for debugging
            setCurrentStep(data.currentStep || 0);
          } else {
            console.log("No such document!");
          }
        } catch (error) {
          console.error('Error fetching user data:', error); // Logging errors
        }
      };
      fetchUserData();
    }
  }, [user]);

  useEffect(() => {
    // Set a timeout to show the content after the typing animation
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 3000); // Adjust this duration to match the typing animation duration

    return () => clearTimeout(timer); // Cleanup timeout on component unmount
  }, []);

  const handleButtonClick = async (nextStep) => {
    if (user) {
      try {
        const userDocRef = doc(db, 'Users', user.uid); // Correctly reference the Firestore document
        await updateDoc(userDocRef, { currentStep: nextStep }); // Update the document
        setCurrentStep(nextStep);
      } catch (error) {
        console.error('Error updating user data:', error); // Logging errors
      }
    }
  };

  const renderContent = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className="content">
            <p className="description">I'm here to help you save time and streamline your scheduling. <br></br>I understand natural language, and I take requests via email, so you don’t need to spend time on another calendar. Let me show you how I can do.</p>
            <div className="progress-container">
              <div className="progress-bar" style={{ width: '25%' }}></div>
            </div>
            <div className="card-container">
              <div className="card">
                <h3>Goal: Set up my first meeting</h3>
                <p>Click the button to open a pre-filled Gmail message. <br></br> <br></br>Hit send, and I will add the an to your calendar.</p>
                <button className="button" onClick={() => handleButtonClick(1)}>Email Ava</button>
              </div>
            </div>
          </div>
        );
      case 1:
        return (
          <div className="content">
            <div className="progress-container">
              <div className="progress-bar" style={{ width: '50%' }}></div>
            </div>
            <div className="card-container">
              <div className="card">
                <h3>Goal: Find time in your calendar</h3>
                <p>Let me find you a good time to relax. Send another email asking me to find you time to relax tomorrow early evening.</p>
                <button className="button" onClick={() => handleButtonClick(2)}>Email Ava</button>
              </div>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="content">
            <div className="progress-container">
              <div className="progress-bar" style={{ width: '60%' }}></div>
            </div>
            <div className="card-container">
              <div className="card">
                <h3>Goal: Schedule a meeting with someone</h3>
                <p>I can schedule a meeting with anyone you cc in the email.</p>
                <button className="button" onClick={() => handleButtonClick(3)}>Email Ava</button>
              </div>
            </div>
          </div>
        );
      case 3:
        return (
          <div className="content">
            <div className="progress-container">
              <div className="progress-bar" style={{ width: '90%' }}></div>
            </div>
            <div className="card-container">
              <div className="card">
                <h3>Goal: Find a time for multiple people</h3>
                <p>When you don't know when to meet, let me pick it up for you. CC whoever you want to invite and ask me to find availability for you all.</p>
                <button className="button" onClick={() => handleButtonClick(4)}>Email Ava</button>
              </div>
            </div>
          </div>
        );
      default:
        return <div>Onboarding Complete! Next time you're in setting up a meeting for multiple people<br></br>, email me, cc your invitees and ask me to find a time! </div>;
    }
  };

  return (
    <div className="main-container">
      <div className="typewriter-container">
        <div className="typewriter">
          <span className="typewriter-text">Hi, I'm Ava.</span>
        </div>
      </div>
      <div className={`content-container ${showContent ? 'show' : ''}`}>
        {renderContent()}
      </div>
      <style>
        {`
          body {
            font-family: Arial, sans-serif;
            background-color: #121212;
            color: #ffffff;
            height: 100vh;
            margin: 0;
          }

          .main-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100vh;
            background-color: #121212; /* Ensure the main container has a black background */
          }

          .typewriter-container {
            display: flex;
            justify-content: center;
            width: 100%;
            padding-bottom: 20px;
          }

          .typewriter {
            position: relative;
            font-family: Arial, sans-serif;
            font-size: 24px; /* Adjust as needed */
            color: #ffffff;
            white-space: nowrap;
          }

          .typewriter::after {
            content: '|';
            position: absolute;
            right: -10px;
            font-size: 24px; /* Adjust to match text size */
            color: orange; /* Set the color to orange */
            animation: blink 0.7s infinite;
          }

          .typewriter-text {
            overflow: hidden;
            display: inline-block;
            animation: typing 3s steps(13, end) forwards;
            width: 0;
          }

          @keyframes typing {
            from { width: 0 }
            to { width: 100% }
          }

          @keyframes blink {
            0%, 100% { opacity: 1; }
            50% { opacity: 0; }
          }

          .content-container {
            opacity: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 600px;
            background-color: #121212; /* Ensure the content container has a black background */
            transition: opacity 2s ease-in-out;
          }

          .content-container.show {
            opacity: 1;
          }

          .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            background-color: #121212; /* Ensure the content container has a black background */
          }

          .description {
            margin-top: 20px;
            font-size: 18px;
            text-align: center;
            background-color: #121212; /* Set background to black */
            padding: 10px; /* Add some padding for better readability */
            border-radius: 8px; /* Optional: rounded corners */
          }

          .progress-container {
            width: 100%;
            background-color: #333;
            border-radius: 8px;
            margin-top: 20px;
            height: 10px;
          }

          .progress-bar {
            height: 100%;
            background: linear-gradient(to right, #ff7e5f, #feb47b);
            border-radius: 8px;
            transition: width 1s ease-in-out;
          }

          .card-container {
            width: 100%;
            margin-top: 20px;
          }

          .card {
            background: #1e1e1e;
            border-radius: 8px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
            padding: 20px;
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 20px;
            width: 100%;
          }

          .card h3 {
            margin-top: 0;
            flex: 1;
          }

          .card p {
            font-size: 16px;
            margin: 0 20px;
            flex: 2;
          }

          .button {
            background: linear-gradient(to right, #ff7e5f, #feb47b);
            border: none;
            color: white;
            padding: 10px 20px;
            text-align: center;
            text-decoration: none;
            display: inline-block;
            font-size: 16px;
            cursor: pointer;
            border-radius: 8px;
            transition: background 0.3s ease-in-out;
            flex: 1;
          }

          .button:hover {
            background: linear-gradient(to right, #feb47b, #ff7e5f);
          }
        `}
      </style>
    </div>
  );
};

export default Welcome;
