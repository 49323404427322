// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyDgJj0G0BUzzPYN3ilyO8za6xU409uF-W8",
  authDomain: "useallie-6adab.firebaseapp.com",
  projectId: "useallie-6adab",
  storageBucket: "useallie-6adab.appspot.com",
  messagingSenderId: "1089731370690",
  appId: "1:1089731370690:web:7620f6860f1b869fce255b",
  measurementId: "G-6RVFN2BPKS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// Initialize a Google Auth Provider
export const googleProvider = new GoogleAuthProvider();

// Initialize Firestore and export it
export const db = getFirestore(app);