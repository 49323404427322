import React, { useState, useEffect } from 'react';
import { auth, db } from './firebase.js';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './App.css';

// Import the SVG icon
import CalendarSettingsIcon from './calendarsettings.svg';
import WelcomeIcon from './welcome.svg';
import BillingIcon from './billing.svg';
import CalendarInsightsIcon from './insights.svg';

const NavBar = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      if (firebaseUser) {
        const userRef = doc(db, 'Users', firebaseUser.uid);
        const userSnapshot = await getDoc(userRef);
        if (userSnapshot.exists()) {
          setUser(userSnapshot.data());
        } else {
          console.error('User profile not found in Firestore');
        }
      } else {
        setUser(null);
        window.location.href = 'https://app.heyava.ai'; // Redirect to external URL when logged out
      }
    });

    return () => unsubscribe();
  }, []);

  const signOut = async () => {
    try {
      await auth.signOut();
      setUser(null);
    } catch (error) {
      console.error('Sign out error', error);
    }
  };

  return (
    <div className="sidebar" style={{ position: 'fixed', top: '0', left: '0', height: '100vh', width: '15%' }}>
      {user ? (
        <div className="user-profile">
          <img src={user.profilePicture} alt="Profile" className="profile-picture" />
          <div className="user-info">
            <span className="user-name">{user.name}</span>
            <span className="user-email">{user.email}</span>
          </div>
        </div>
      ) : (
        <div className="sign-in-message">
          Please sign in to view your profile
        </div>
      )}
      <div className="menu-items">
        <div className="menu-item">
          <Link to="/Welcome">
            <img src={WelcomeIcon} alt="Welcome" className="icon" />
            <span className="menu-text">Welcome</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to="/Billing">
            <img src={BillingIcon} alt="Billing" className="icon" />
            <span className="menu-text">Billing</span>
          </Link>
        </div>
        <div className="menu-item">
          <Link to="/calendar-insights">
            <img src={CalendarInsightsIcon} alt="Calendar Insights" className="icon" />
            <span className="menu-text">Calendar Insights</span>
          </Link>
        </div>
      </div>
      {user && (
        <button className="sign-out-button" onClick={signOut}>
          Sign Out
        </button>
      )}
    </div>
  );
};

export default NavBar;
