import React, { useState } from 'react';
import './App.css';
import { AuthProvider, useAuth } from './AuthContext.js';
import SignIn from './SignIn.js';
import NavBar from './NavBar.js';
import CalendarInsights from './CalendarInsights.js'; // Import the new component
import Dashboard from './Dashboard.js';
import Welcome from './Welcome.js';
import BarChart from './RocketDeck.js';
import CalendarSettings from './CalendarSettings.js';
import GoogleCallback from './GoogleCallback.js';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import HelpIcon from './HelpIcon.js';
// import HelpMenu from './HelpMenu.js';
// import ChatBox from './ChatBox.js';
import Billing from './Billing.js';
import useIntercom from './useIntercom.js'; // Import the custom hook
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);



function App() {
  const { user } = useAuth(); // Destructure user from the context
  useIntercom(user); // Initialize Intercom based on user state

  // State to control visibility of HelpMenu and ChatBox
  // const [showHelpMenu, setShowHelpMenu] = useState(false);
  // const [showChatBox, setShowChatBox] = useState(false);

  // Function to toggle HelpMenu visibility
  //const handleHelpIconClick = () => {
  //  setShowHelpMenu(!showHelpMenu);
  // };

  // Function to open ChatBox and hide HelpMenu
 // const handleMessageSupportClick = () => {
 //   setShowHelpMenu(false);
 //   setShowChatBox(true);
//  };

  // Function to close ChatBox
//  const handleChatBoxClose = () => {
//    setShowChatBox(false);
//  };

  // Function to open the chat
//  const openChat = () => {
//    console.log('Open chat clicked');
    // Your chat opening logic goes here
//  };

  return (
<AuthProvider>
      <Router>
        <div className={user ? "App" : "App center-sign-in"}>
          {user && <NavBar />}
          <Elements stripe={stripePromise}>
            <Routes>
              <Route path="/" element={user ? <Welcome /> : <SignIn />} />
              <Route path="/welcome" element={<Welcome />} />
              <Route path="/billing" element={<Billing />} />
              <Route path="/rocketdeck" element={<BarChart />} />
              <Route path="/google/callback" element={<GoogleCallback />} />
              <Route path="/calendar-insights" element={<CalendarInsights />} /> {/* Use element prop */}
              {/* Any other routes that need Stripe functionality should also be inside <Elements> */}
            </Routes>
          </Elements>
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;